const WavyLine = () => (
  <div style={{ 
    position: 'absolute',
    width: '100vw',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: -1,
    overflow: 'hidden',
    opacity: 0.2 
  }}>

      <svg 
          width="100%" 
          height="100%" 
          viewBox="0 0 1269 1312" 
          preserveAspectRatio="none"
          fill="none" 
          xmlns="http://www.w3.org/2000/svg">
          <mask id="mask0_29_7" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="1269" height="1312">
              <rect width="1269" height="1312" fill="url(#paint0_linear_29_7)"/>
          </mask>
          <g mask="url(#mask0_29_7)">
              <path fillRule="evenodd" clipRule="evenodd" d="M0 53L42.3 76.9259C84.6 100.852 169.2 148.704 253.8 154.685C338.4 160.667 423 124.778 507.6 142.722C592.2 160.667 676.8 232.444 761.4 244.407C846 256.37 930.6 208.519 1015.2 172.63C1099.8 136.741 1184.4 112.815 1226.7 100.852L1269 88.8889L1269 1108H1226.7C1184.4 1108 1099.8 1108 1015.2 1108C930.6 1108 846 1108 761.4 1108C676.8 1108 592.2 1108 507.6 1108C423 1108 338.4 1108 253.8 1108C169.2 1108 84.6 1108 42.3 1108H0V53Z" fill="url(#paint1_linear_29_7)"/>
          </g>
          <defs>
              <linearGradient id="paint0_linear_29_7" x1="635" y1="52" x2="728.494" y2="1051.25" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#D9D9D9"/>
                  <stop offset="0.714286" stopColor="#878787" stopOpacity="0.2"/>
                  <stop offset="1" stopColor="#737373" stopOpacity="0"/>
              </linearGradient>
              <linearGradient id="paint1_linear_29_7" x1="0" y1="0" x2="1269" y2="1312" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#115989"/>
                  <stop offset="0.153486" stopColor="#2C5192"/>
                  <stop offset="1" stopColor="#C428C1"/>
              </linearGradient>
          </defs>
      </svg>
  </div>
);
export default WavyLine;
