import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import placeholder from '../pics/BGmain.jpg';

const BlogGrid = () => {
const [blogs, setBlogs] = useState([]);

useEffect(() => {
  fetch('http://localhost:5000/api/blogs')
    .then(res => res.json())
    .then(data => {
      if (Array.isArray(data)) {
        setBlogs(data);
      } else {
        setBlogs([]);
      }
    })
    .catch(error => {
      console.log('Error:', error);
      setBlogs([]);
    });
}, []);

  return (
    <div className="container mx-auto px-4 py-8 min-h-screen">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {blogs.map((blog) => (
          <Link 
            to={`/blog/${blog.slug}`} 
            key={blog.id} 
            className="flex flex-col bg-white rounded-lg shadow-xl hover:shadow-2xl transition-shadow duration-300 overflow-hidden"
          >
            <div className="aspect-video w-full bg-gray-200">
              {blog.imageUrl ? (
                <img 
                  src={blog.imageUrl} 
                  alt={blog.title}
                  className="w-full h-full object-cover"
                />
              ) : (
                <div className="w-full h-full flex items-center justify-center text-gray-400">
                  <img src={placeholder} alt="placeholder" className="h-32 w-full object-cover" />
                </div>
              )}
            </div>
            <div className="p-6">
              <h2 className="text-2xl font-bold mb-3 text-gray-800 line-clamp-2">{blog.title}</h2>
              <div className="flex items-center space-x-4 mb-4">
                <p className="text-sm font-medium text-gray-600">by {blog.author}</p>
                <span className="text-gray-300">•</span>
                <p className="text-sm text-gray-500">{new Date(blog.date).toLocaleDateString()}</p>
              </div>
              <p className="text-gray-600 line-clamp-3">{blog.content}</p>
              <div className="mt-4 inline-flex items-center text-indigo-600 hover:text-indigo-800">
                Read More 
                <svg className="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                </svg>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default BlogGrid;