import React, { useState } from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom';

const FormComponent = ({ onClose = () => {} }) => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    postcode: ''  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const validateForm = () => {
    let errors = {};
    if (!formData.name.trim()) errors.name = "Name is required";
    if (!formData.phone.trim()) errors.phone = "Phone number is required";
    if (!formData.email.trim()) errors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(formData.email)) errors.email = "Email is invalid";
    if (!formData.postcode.trim()) errors.postcode = "Postcode is required";
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }
    setIsSubmitting(true);
    try {
      const response = await axios.post('http://localhost:3000/api/submit-form', formData);
      setSubmitStatus('success');
      console.log('Form submitted successfully:', response.data);
      setFormData({ name: '', phone: '', email: '', postcode: '' }); // Reset all fields
    } catch (error) {
      setSubmitStatus('error');
      console.error('Error submitting form:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    onClose();
  };

  const modalContent = (
    <div className="fixed z-20 top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
      <form onSubmit={handleSubmit} className="bg-white p-8 rounded-lg shadow-lg relative max-w-md w-full">
        <button 
          onClick={handleClose} 
          type="button"
          className="absolute top-2 right-2 w-8 h-8 flex items-center justify-center rounded-full hover:bg-primary hover:text-white transition-colors duration-200"
          aria-label="Close form"
        >
          ×
        </button>
        <h2 className="text-2xl font-bold mb-4">Contact Information</h2>
        {['name', 'phone', 'email', 'postcode'].map((field) => (
          <div key={field} className="mb-4">
            <label htmlFor={field} className="block text-sm font-medium text-gray-700 capitalize">{field}</label>
            <input
              type={field === 'email' || field === 'recipientEmail' ? 'email' : 'text'}
              id={field}
              name={field}
              value={formData[field]}
              onChange={handleChange}
              className={`mt-1 p-2 border rounded w-full ${errors[field] ? 'border-red-500' : ''}`}
            />
            {errors[field] && <p className="text-red-500 text-xs mt-1">{errors[field]}</p>}
          </div>
        ))}
        <button
          type="submit"
          disabled={isSubmitting}
          className={`w-full font-bold text-white p-3 rounded-lg transition duration-300 ${
            isSubmitting ? 'bg-gray-400' : 'bg-primary hover:bg-secondary'
          }`}
        >
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </button>
        {submitStatus === 'success' && (
          <p className="text-green-500 mt-2">Form submitted successfully!</p>
        )}
        {submitStatus === 'error' && (
          <p className="text-red-500 mt-2">Error submitting form. Please try again.</p>
        )}
      </form>
    </div>
  );

  return ReactDOM.createPortal(
    modalContent,
    document.body
  );
};export default FormComponent;