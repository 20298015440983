import React, { useState, useEffect } from 'react';
import JSONEditor from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
 // eslint-disable-next-line
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlogList from '../Components/BlogList';

const JsonEditorPage = () => {
  const [jsonData, setJsonData] = useState({
    title: '',
    author: '',
    content: '',
    image_url: '',
    featured: false
  });
   // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async () => {
    setIsLoading(true);
    const formattedData = {
      ...jsonData,
      slug: jsonData.title.toLowerCase().replace(/[^a-z0-9]+/g, '-'),
      date: new Date().toISOString()
    };

    try {
      const response = await fetch('http://localhost:5000/api/blogs', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(formattedData)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log('Upload successful:', result);
      
      setJsonData({
        title: '',
        author: '',
        content: '',
        image_url: '',
        featured: false
      });

      toast.success('Blog post successfully submitted!');

    } catch (error) {
      console.error('Upload failed:', error);
      if (error.message.includes('Failed to fetch')) {
        toast.error('Unable to connect to the database. Please check if the server is running.');
      } else {
        toast.error(`Failed to submit blog post: ${error.message}`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setJsonData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleJsonChange = (data) => {
    if (data.jsObject) {
      setJsonData(data.jsObject);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <ToastContainer position="top-right" />
      <h1 className="text-2xl font-bold mb-4">Blog Editor</h1>
      
      <div className="grid gap-4 mb-6">
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Title
          </label>
          <input
            name="title"
            type="text"
            value={jsonData.title}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
          />
        </div>

        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Author
          </label>
          <input
            name="author"
            type="text"
            value={jsonData.author}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
          />
        </div>

        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Content
          </label>
          <textarea
            name="content"
            value={jsonData.content}
            onChange={handleInputChange}
            className="w-full p-2 border rounded h-32"
          />
        </div>

        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Image URL
          </label>
          <input
            name="image_url"
            type="url"
            value={jsonData.image_url}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
          />
        </div>

        <div>
          <label className="flex items-center">
            <input
              type="checkbox"
              name="featured"
              checked={jsonData.featured}
              onChange={handleInputChange}
              className="mr-2"
            />
            <span className="text-gray-700 text-sm font-bold">Featured Post</span>
          </label>
        </div>
      </div>

      <div className="mb-6">
        <h2 className="text-lg font-bold mb-2">JSON Preview</h2>
        <JSONEditor
          placeholder={jsonData}
          locale={locale}
          onChange={handleJsonChange}
          height="200px"
        />
      </div>

      <button 
        onClick={handleSave}
        disabled={isLoading}
        className={`bg-blue-500 text-white px-4 py-2 rounded ${isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'}`}
      >
        {isLoading ? 'Saving...' : 'Save Blog'}
      </button>

      <div className="mt-8">
        <BlogList />
      </div>
    </div>
  );
};

export default JsonEditorPage;