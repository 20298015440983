import { motion } from 'framer-motion';
import FormComponent from '../Components/ConForm';
import React, { useState } from 'react';
import RatingSubtitle from './RatingSubtitle';

export const Hero = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);

  const showForm = () => {
    setIsFormOpen(true);
  };

  return (
    <div className="relative min-h-[calc(40vh+80px)] flex items-center bg-transparent w-full overflow-x-hidden p-8">
      <div className="w-full max-w-7xl mx-auto rounded-[3rem] bg-white/80 backdrop-blur-sm p-12 relative overflow-hidden">
        <div className="absolute inset-0 -z-10">
          <div className="w-full h-full rounded-[3rem] overflow-hidden Walkingimg">
          </div>
        </div>
        <div className="space-y-2 md:space-y-2 relative z-10">
          <motion.div
            initial={{ y: -25, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 1, ease: "easeOut" }}
            className="space-y-3 md:space-y-4"
          >
            <h1 className="text-3xl sm:text-5xl md:text-6xl lg:text-7xl font-bold tracking-tight text-gray-900">
              Step to Wellness,
            </h1>
            <h1 className="text-3xl sm:text-5xl md:text-6xl lg:text-7xl font-bold tracking-tight">
              One <span className='bg-gradient-to-r from-secondary to-primary bg-clip-text text-transparent'>Foot</span> at a Time.
            </h1>
            <motion.p
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 1, delay: 0.2 }}
              className="mt-4 md:mt-6 text-lg md:text-xl text-gray-600 max-w-3xl"
            >
              Experience bespoke podiatric care and professional guidance for your optimal foot health and wellbeing.
            </motion.p>
          </motion.div>

          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.4 }}
            className="flex flex-col items-start space-y-4"
          >
            <button
              onClick={showForm}
              className="transform hover:scale-105 transition-all duration-300 px-6 sm:px-8 py-3 sm:py-4 rounded-full bg-gradient-to-r from-primary to-secondary text-white font-semibold text-base sm:text-lg shadow-xl hover:shadow-2xl focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50"
            >
              Book a Consultation
            </button>
            <motion.div
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.8, delay: 0.6 }}
              className="text-xs sm:text-sm text-gray-500"
            ><RatingSubtitle />
{/* <h2 class="text-center text-lg md:text-xl font-semibold text-gray-800">⭐⭐⭐⭐⭐ Rated 5.0 by our satisfied patients</h2> */}
            </motion.div>          </motion.div>

          {isFormOpen && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
              className="w-full"
            >
              <FormComponent onClose={() => setIsFormOpen(false)} />
            </motion.div>
          )}
        </div>
      </div>
      
      {/* Background decoration */}
      <div className="absolute inset-0 -z-10 overflow-hidden">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.1 }}
          transition={{ duration: 2 }}
          className="absolute -top-[25vh] -right-[25vh] w-[150vh] h-[150vh] rounded-full border-2 border-primary/30 overflow-hidden"
        >
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.1 }}
          transition={{ duration: 2 }}
          className="absolute -bottom-40 -left-40 w-[500px] h-[500px] rounded-full border-2 border-secondary/30 overflow-hidden"
        >
        </motion.div>
      </div>
    </div>
  );
};

export default Hero;
