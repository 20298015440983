import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './Components/header.jsx';
import MainPage from './pages/MainPage.jsx';
import AboutPage from './pages/about.jsx';
import Footer from './Components/footer.jsx';
import ServicePage from './pages/services.jsx';
import ConForm from './pages/ConsultationForm.jsx';
import CookieConsent from'./Components/CookiesConsent.jsx';
import NotFoundPage from './pages/NotFoundPage';
import OrthoticsPage from './pages/Orthotics.jsx';
import Blog from './pages/Blog';
import BlogPost from './Components/BlogPost.jsx';
import BlogEditor from './pages/JsonEditorPage.jsx'
import { initializeApp } from "firebase/app";


 // eslint-disable-next-line
function App() {

  const firebaseConfig = {
    apiKey: "AIzaSyD2EU9pkRmCv3zyPg_Qjr3GH-daP7LWuTs",
    authDomain: "droshpod-f9998.firebaseapp.com",
    projectId: "droshpod-f9998",
    storageBucket: "droshpod-f9998.appspot.com",
    messagingSenderId: "613119868120",
    appId: "1:613119868120:web:ddf6a51c14019152562bad"
    
  }
   // eslint-disable-next-line
  const app = initializeApp(firebaseConfig); 

  return (
    <Router basename="/">
      <div className="flex flex-col min-h-screen">
        <Header />
        <div className="flex-grow mt-16">
          <Routes>
            <Route exact path="/" element={<MainPage />} />
            <Route exact path="/about" element={<AboutPage />} />
            <Route exact path="/services" element={<ServicePage />} />
            <Route exact path="/ConsultationForm" element={<ConForm/>}/>
            <Route exact path="/Orthotics" element={<OrthoticsPage />} />
            <Route exact path="/blog" element={<Blog />} />
            <Route exact path="/blog/editor" element={<BlogEditor />} />
            <Route exact path="/blog/:id" element={<BlogPost />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </div>
        <CookieConsent />
        <Footer/>
      </div>
  
    </Router>
    
  );
 // eslint-disable-next-line
}export default App;